<template>
  <div>
    <b-spinner v-if="admin === null" type="grow" label="Loading..."></b-spinner>
    <RegularSettingsProfile v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RegularSettingsProfile from '../components/settings_account/RegularSettingsProfile.vue';

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters({ getUserByStore: 'loginStore/getUser' }),

    admin() {
      if (this.getUserByStore.Role) {
        return this.getUserByStore.Role.Id === '8216083';
      }
      return null;
    }
  },

  methods: {},

  components: {
    RegularSettingsProfile
  }
};
</script>

<style scoped></style>
