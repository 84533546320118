<template>
  <div style="max-width: 500px">
    <b-form @submit.prevent="updateUser">
      <b-form-group label="Пароль" label-for="password">
        <b-form-input
          disabled
          id="password"
          v-model="password"
          type="text"
          size="sm"
          placeholder="Пароль"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Имя" label-for="userName">
        <b-form-input
          id="userName"
          v-model="userName"
          type="text"
          size="sm"
          placeholder="Имя пользователя"
          :disabled="getUser.DogUser"
        ></b-form-input>
      </b-form-group>
      <b-spinner v-if="loading" type="grow" label="Loading..."></b-spinner>
      <b-button v-else type="submit" variant="primary" size="sm" :disabled="getUser.DogUser">
        Обновить
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      password: '',
      userName: '',
      loading: false
    };
  },

  computed: {
    ...mapGetters({ getUser: 'loginStore/getUser', selectUser: 'loginStore/selectUser' })
  },

  methods: {
    ...mapActions({ updateUser: 'profileSettings/updateUser' }),

    async updateUser() {
      this.loading = true;
      const user = {
        Password: this.password,
        Name: this.userName,
        id: this.getUser.Id,
        RoleId: +this.getUser.Role.Id
      };
      await this.getUser(user);
      await this.selectUser();
      this.loading = false;
    }
  },

  beforeMount() {
    this.userName = this.getUser.DisplayName;
  }
};
</script>

<style></style>
